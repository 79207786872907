<template>
  <div :class="classList.modal">
    <div :class="classList.modalDialog">
      <div :class="classList.modalContent">
        <button :class="classList.closeButton" @click="handleCloseButtonClick">
          <CloseIcon />
        </button>

        <div :class="classList.modalHeader">
          <h5 :class="classList.modalTitle">
            {{ t('form_h1') }}
          </h5>
        </div>
        <div :class="classList.modalBody">
          <Message :type="messageTypeEnum.FAILURE" :message="message" />
          <Form :class="classList.form" :ref="formRef">
            <section>
              <TextField
                :type="inputType.EMAIL"
                :name="name.EMAIL"
                :label="t('form_label_email')"
                v-model="formData[name.EMAIL]"
                :validators="[validator.required, validator.email]"
                :serverError="serverError[name.EMAIL]"
                autocomplete="off"
                @enter="handleEnterPress"
              />
              <PasswordField
                :name="name.PASSWORD"
                :label="t('form_label_passwd')"
                v-model="formData[name.PASSWORD]"
                :validators="[validator.required]"
                :serverError="serverError[name.PASSWORD]"
                :containerClass="classList.passwordClass"
                autocomplete="off"
                @enter="handleEnterPress"
              />
              <div :class="classList.formGroup">
                <p :class="classList.forgotPassword">
                  <span>{{ t('forgot_your_password') }}</span>
                  <button
                    :class="classList.redirectButton"
                    @click="handleForgotPasswordButtonClick"
                  >
                    {{ t('click_here') }}
                  </button>
                </p>
              </div>
            </section>
            <button
              :class="classList.submitButton"
              @click="submitForm"
              :disabled="isSubmitting"
            >
              <ButtonLoader v-if="isSubmitting" />
              <span v-else>{{ t('submit_login_btn_label') }}</span>
            </button>
            &nbsp;
          </Form>
          <div :class="classList.line"></div>
          <p :class="classList.footer">
            {{ t('no_account') }}
            <button
              :class="classList.redirectButton"
              @click="handleFooterButtonClick"
            >
              {{ t('register_now') }}
            </button>
          </p>
          <div v-if="isGermanTranslation" class="csn-register-german-notice">
            <img src="@/assets/images/icons/general/Alert.svg" alt="warning" />
            <span>
              {{ t('german_notice') }}
              <a href="https://www.bzga.de/">BZgA.de</a>.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { formMixin, modalMixin } from '@/mixins'
import { LoginApi } from '@/api'
import {
  RouteName,
  Module,
  CURRENT_LOCATION,
  UPDATE_PERSONAL_INFO,
  UPDATE_CASINO_INFO,
  SET_USER_EMAIL,
  IS_LOGGED,
  UPDATE_RECENT_GAME_DICTIONARY,
  UPDATE_FAVOURITE_GAME_DICTIONARY,
  IS_EMPTY_USER_ADDRESS,
  LOGIN_MODAL,
  EMPTY_STRING,
  EventType,
  REMOVE_FLASH,
  HAS_LOGOUT_FLASH,
  FlashId,
  IS_LOGIN_PAGE,
  MODAL_DIALOG,
  MODAL_CONTENT,
  MODAL_HEADER,
  MODAL_TITLE,
  MODAL_BODY,
  CSN_MODAL_CLOSE_BUTTON,
  LINE_CLASS,
  FOOTER_CLASS,
  BTN_CLASS,
} from '@/constants'
import { dest, navigateTo } from '@/helpers'
import { getTitle } from '../router/getTitle'

const CSN_FORM_LOGIN = 'csn-user-login'
const CSN_USER_LOGIN_FORM = `${CSN_FORM_LOGIN}-form`
const CSN_USER_LOGIN_FORM_MODAL = `${CSN_USER_LOGIN_FORM}-modal`
const CSN_USER_LOGIN_FORM_PASSWORD = `${CSN_USER_LOGIN_FORM}-password`
const FORM_GROUP = 'form-group'
const CSN_USER_LOGIN_FORGOT_PASSWORD = `${CSN_FORM_LOGIN}-forgot-password`
const formClass = `col-7 mx-auto csn-modal-content-form`
const submitButtonClass = `${BTN_CLASS} casino-btn casino-btn-main-menu pull-left btn-full-width`
const CSN_MODEL_CONTENT_REDIRECT_BUTTON = 'csn-modal-content-redirect-button'

const FORM_NAME = 'login_'

const name = {
  EMAIL: `${FORM_NAME}username`,
  PASSWORD: `${FORM_NAME}password`,
}

const formData = {
  [name.EMAIL]: EMPTY_STRING,
  [name.PASSWORD]: EMPTY_STRING,
}

export default {
  name: LOGIN_MODAL,
  mixins: [formMixin, modalMixin],
  components: {
    Form: () => import('@/components/FormData'),
    TextField: () => import('@/components/TextField'),
    ButtonLoader: () => import('@/components/ButtonLoader'),
    Message: () => import('@/components/atoms/Message'),
    CloseIcon: () => import('@/components/svg/CloseIcon'),
    PasswordField: () => import('@/components/PasswordField'),
  },
  data() {
    return {
      formData: { ...formData },
    }
  },
  computed: {
    name: () => name,
    classList: () => ({
      modal: CSN_USER_LOGIN_FORM_MODAL,
      modalDialog: MODAL_DIALOG,
      modalContent: MODAL_CONTENT,
      closeButton: [BTN_CLASS, CSN_MODAL_CLOSE_BUTTON],
      modalHeader: MODAL_HEADER,
      modalTitle: MODAL_TITLE,
      modalBody: MODAL_BODY,
      form: formClass,
      passwordClass: CSN_USER_LOGIN_FORM_PASSWORD,
      formGroup: FORM_GROUP,
      forgotPassword: [FOOTER_CLASS, CSN_USER_LOGIN_FORGOT_PASSWORD],
      submitButton: submitButtonClass,
      line: LINE_CLASS,
      footer: FOOTER_CLASS,
      redirectButton: CSN_MODEL_CONTENT_REDIRECT_BUTTON,
    }),
    ...mapState(Module.LOCATION, [CURRENT_LOCATION]),
    ...mapGetters({ isLogged: dest([Module.USER, IS_LOGGED]) }),
    ...mapGetters({ hasNoAddress: dest([Module.USER, IS_EMPTY_USER_ADDRESS]) }),
    ...mapGetters({ hasLogoutFlash: dest([Module.FLASH, HAS_LOGOUT_FLASH]) }),
    ...mapGetters({ isLoginPage: dest([Module.LOCATION, IS_LOGIN_PAGE]) }),
    nextUri() {
      //NOTE: this is to trace a subtle bug
      console.log('NEXT_URI', {
        fullPath: this.CURRENT_LOCATION?.query?.redirect_uri,
        name: this.CURRENT_LOCATION?.query?.redirect_name,
      })

      const isNextUriLogin =
        this.CURRENT_LOCATION?.query?.redirect_name === RouteName.LOGIN
      const isNextUriHome =
        this.CURRENT_LOCATION?.query?.redirect_name === RouteName.HOME
      const hasNextUriName = this.CURRENT_LOCATION?.query?.redirect_name
      const hasNextUriFullPath = !!this.CURRENT_LOCATION?.query?.redirect_uri
      const shallGoToHomepage =
        isNextUriLogin ||
        isNextUriHome ||
        !hasNextUriName ||
        !hasNextUriFullPath

      return shallGoToHomepage
        ? { name: RouteName.HOME }
        : this.CURRENT_LOCATION?.query?.redirect_uri
    },
    t() {
      return this.$createComponentTranslator(LOGIN_MODAL)
    },
    isGermanTranslation() {
      return window.i18n.locale.toLocaleUpperCase() === 'DE'
    },
  },
  watch: {
    formData: {
      deep: true,
      handler(currentFormData) {
        if (!Object.keys(this.serverError).length) {
          return
        }

        Object.keys(this.serverError).forEach((key) => {
          if (currentFormData[key] === this.formDataSubmitCopy[key]) {
            return
          }

          this.serverError = {}
          this.message = null
        })
      },
    },
  },
  methods: {
    ...mapActions(Module.USER, [UPDATE_PERSONAL_INFO, SET_USER_EMAIL]),
    ...mapActions(Module.CASINO_INFO, [UPDATE_CASINO_INFO]),
    ...mapActions(Module.RECENT_GAME, [UPDATE_RECENT_GAME_DICTIONARY]),
    ...mapActions(Module.FAVOURITE_GAME, [UPDATE_FAVOURITE_GAME_DICTIONARY]),
    ...mapMutations(Module.FLASH, [REMOVE_FLASH]),

    goToForgotPassword: () => navigateTo({ name: RouteName.FORGOT_PASSWORD }),
    closeModal(fn) {
      return this.$emit(EventType.CLOSE, fn)
    },
    goToRegistration() {
      this.$router.history.current.name !== RouteName.REGISTRATION &&
        navigateTo({ name: RouteName.REGISTRATION })
    },
    changeRouteOnSubmit() {
      if (this.hasNoAddress) {
        navigateTo({ name: RouteName.USER_EDIT })
        return
      }
      if (this.isLoginPage) {
        navigateTo(this.nextUri)
        return
      }

      getTitle(this.$router?.history?.current)
    },
    async submitForm() {
      const { isValid } = this.$refs[this.formRef].getValidation()

      if (!isValid) {
        return
      }

      this.isSubmitting = true

      try {
        const formDataRequest = {}
        Object.keys(this.formData).forEach((key) => {
          formDataRequest[key.replace(FORM_NAME, EMPTY_STRING)] = this.formData[
            key
          ]
        })

        const response = await LoginApi.loginSession(formDataRequest)

        if (!this.validateResponse(response)) {
          return
        }

        const { msg, state } = response
        const email = this.formData[name.EMAIL]

        if (this.responseState.ERROR === state) {
          msg && (this.message = msg)
          this.formDataSubmitCopy = { ...this.formData }
          this.isSubmitting = false
          return
        }

        await Promise.all([
          this.UPDATE_PERSONAL_INFO(),
          this.UPDATE_CASINO_INFO(),
          this.UPDATE_RECENT_GAME_DICTIONARY(),
          this.UPDATE_FAVOURITE_GAME_DICTIONARY(),
        ])

        this.dropLogoutSuccessFlash()
        this.isLogged && this.SET_USER_EMAIL({ email })
        this.isSubmitting = false
        this.$refs[this.formRef].reset()
        this.closeModal(this.changeRouteOnSubmit)
      } catch (error) {
        console.dir(error)
      }
    },
    handleCloseButtonClick() {
      this.closeModal(this.goToHomepage)
    },
    handleFooterButtonClick() {
      this.closeModal(this.goToRegistration)
    },
    handleForgotPasswordButtonClick() {
      this.closeModal(this.goToForgotPassword)
    },
    dropLogoutSuccessFlash() {
      this.hasLogoutFlash && this.REMOVE_FLASH({ id: FlashId.LOGOUT_SUCCESS })
    },
    handleEnterPress() {
      this.$refs[this.formRef].makeReady({ isReady: true })
      this.submitForm()
    },
  },
  beforeRouteLeave(to, from, next) {
    this.formData = { ...formData }
    next()
  },
}
</script>
